// extracted by mini-css-extract-plugin
export var anchor = "faq-module--anchor--7f28a";
export var body = "faq-module--body--e4fff";
export var close = "faq-module--close--272cf";
export var content = "faq-module--content--f0abe";
export var faq = "faq-module--faq--e5ff1";
export var heading = "faq-module--heading--ab7e4";
export var panel = "faq-module--panel--77414";
export var question = "faq-module--question--b5a87";
export var tab = "faq-module--tab--656bd";
export var title = "faq-module--title--77749";